<template>
  <!-- footert -->
  <footer class="mt-5">
    <div class="container footer-bottom clearfix">
      <div class="copyright">
        © Copyright <strong><span>KVWSMB</span></strong
        >. All Rights Reserved
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped></style>
