var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"category"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Category),expression:"form.Category"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"category","placeholder":"Enter Name"},domProps:{"value":(_vm.form.Category)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Category", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"description"}},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",attrs:{"type":"text","id":"description"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}})])]),(!_vm.saving)?_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"submit"}},[_vm._v(" Save ")]):_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")]),_vm._t("cancel")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }