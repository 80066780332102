<template>
  <div class="waterquality h-100">
    <div class="card border-0 h-100">
      <div class="card-body border-0 shadow">
        <div class="row mb-3">
          <div class="col-md-12 d-flex align-items-center">
            <div class="mainhead">
              <h4 class="fw-bold d-flex align-items-center mb-4">
                <div class=" "><i class="bi bi-moisture"></i></div>
                Water Quality
              </h4>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center w-100 justify-content-start">
              <!--  <div class="form-group me-3">
                <div class="flex-row d-flex justify-content-center">
                  <div class="input-group input-daterange">
                    <input
                      type="date"
                      class="form-control"
                      v-model="selection.date"
                      @change="getData"
                    />
                  </div>
                </div>
              </div> -->
              <div class="form-group me-3">
                <div class="btn-group">
                  <button
                    class="btn btn-outline-secondary btn-sm rounded-0 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Select Locations
                  </button>
                  <ul
                    class="dropdown-menu p-3"
                    style="
                      height: 20rem;
                      width: 12rem;
                      overflow-y: scroll;
                      overflow-x: hidden;
                    "
                  >
                    <!-- <li class="row">
                      <div class="col-md-12">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="water-quality-location-all"
                            v-model="selection.locations"
                            value="all"
                            @change="selectAll('location')"
                          />
                          <label class="form-check-label" for="location-all">
                            All
                          </label>
                        </div>
                      </div>
                    </li> -->
                    <li class="row">
                      <div
                        class="col-md-12"
                        v-for="(location, index) in options.locations"
                        :key="index"
                      >
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :id="`water-quality-location-${index}`"
                            v-model="selection.locations"
                            :value="location.Localname"
                            @change="
                              selection.variables = '';
                              loading = true;
                              getData(index);
                            "
                          />
                          <label
                            class="form-check-label"
                            :for="`water-quality-location-${index}`"
                          >
                            {{ location.Localname }}
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group me-3">
                <div class="btn-group">
                  <button
                    class="btn btn-outline-secondary btn-sm rounded-0 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Select Variables
                  </button>
                  <ul
                    class="dropdown-menu p-3"
                    style="
                      height: 20rem;
                      width: 12rem;
                      overflow-y: scroll;
                      overflow-x: hidden;
                    "
                  >
                    <!--  <li class="row">
                      <div class="col-md-12">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="variable-all"
                            v-model="selection.variables"
                            value="all"
                            @change="selectAll('variable')"
                          />
                          <label class="form-check-label" for="variable-all">
                            All
                          </label>
                        </div>
                      </div>
                    </li> -->
                    <li class="row">
                      <div
                        class="col-md-12"
                        v-for="(variable, index) in options.variables"
                        :key="index"
                      >
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            :id="`water-quality-variable-${index}`"
                            v-model="selection.variables"
                            :value="variable.variable"
                            @change="
                              loading = true;
                              var_d = true;
                              getData();
                            "
                          />
                          <label
                            class="form-check-label"
                            :for="`water-quality-variable-${index}`"
                          >
                            {{ variable.variable }}
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group me-3">
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="
                    loading = true;
                    resetFilter();
                  "
                >
                  Reset
                </button>
              </div>
              <div class="form-group me-3">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#selectionValuesModal"
                  class="btn btn-sm btn-primary addnewbtn"
                >
                  Show Selected values
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="water_timeline watermap"
          v-if="!loading"
          @click.prevent="viewLarge"
        >
          <!--          <div id="chart-container"></div>-->
          <highcharts
            :constructorType="'stockChart'"
            :options="chartOptions"
          ></highcharts>
        </div>
        <div v-else class="water_timeline watermap spinner-content">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
    </div>

    <button
      class="d-none"
      ref="viewLarge"
      data-bs-toggle="modal"
      data-bs-target="#waterQualityModal"
    ></button>
    <div
      class="modal fade"
      id="selectionValuesModal"
      tabindex="-1"
      aria-labelledby="selectionValuesLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Selected Values</h5>
            <button
              type="button"
              ref="closeBtn"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <table class="table table-bordered">
                  <caption>
                    <strong>Location</strong>
                  </caption>
                  <thead class="selection">
                    <tr>
                      <th>S/N</th>
                      <th>Location Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(loc, i) in selection.locations" :key="i">
                      <td>{{ ++i }}</td>
                      <td>{{ loc }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-bordered">
                  <caption>
                    <strong>Variable</strong>
                  </caption>
                  <thead class="selection">
                    <tr>
                      <th>S/N</th>
                      <th>Variable Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{{ selection.variables }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="waterQualityModal"
      tabindex="-1"
      aria-labelledby="waterQualityModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="waterQualityModalLabel">
              Water Quality (
              <span class="text-theme">{{
                selection.variables.toString()
              }}</span>
              )
            </h5>
            <button
              type="button"
              ref="closeBtn"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-if="!loading">
            <highcharts
              :constructorType="'stockChart'"
              :options="chartOptions"
            ></highcharts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WaterQualityChart",
  data() {
    return {
      var_d: false,
      loading: true,
      timeSeries: [],
      meta: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 10,
      },
      selection: {
        variables: "",
        locations: [],
        date: "",
      },
      options: {
        variables: [],
        locations: [],
      },
      colors: [
        "#d82f75", //pink shade
        "#2fd86a", //green shade
        "#d6c11e", //yellow shade
        "#138cad", //teal shade
        "#8c13ad", //purple shade
      ],
      chartOptions: null,
    };
  },
  mounted() {
    this.getSelectionData();
  },
  methods: {
    checkMax(i) {
      let input = document.getElementById(`water-quality-location-${i}`);
      let total = this.selection.locations.length;
      if (input.checked) {
        total = total + 1;
      }
      if (total > 5) {
        alert("please select only 5");
        input.checked = false;
      }
    },
    viewLarge() {
      this.$refs.viewLarge.click();
    },
    async getSelectionData() {
      /* await this.$repository.variables.all().then((res) => {
        this.options.variables = res.data.results;
      }); */

      await this.$repository.location.all().then((res) => {
        this.options.locations = res.data.results;
      });

      /*  this.options.variables = this.options.variables.filter(
        (v, i, a) => a.findIndex((t) => t.variable === v.variable) === i
      ); */

      this.options.locations = this.options.locations.filter(
        (v, i, a) => a.findIndex((t) => t.Localname === v.Localname) === i
      );

      await this.sortLocation();
    },
    sortLocation() {
      if (this.options.locations.length) {
        this.options.locations = this.options.locations.sort(function (a, b) {
          var textA = a.Localname.toUpperCase();
          var textB = b.Localname.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        this.selection.locations.push(this.options.locations[0].Localname);
        /*  let variables = this.options.locations[0].variable;
        if (variables && variables.length) {
          this.selection.variables.push(variables[0].variable);
        } */

        //remove comma from Localname
        //this.removeCommaFromLocalname();
      }
      this.getData();
    },
    async removeCommaFromLocalname() {
      if (this.selection.locations.length) {
        this.selection.locations = await this.selection.locations.map((el) => {
          return el.replaceAll(",", "");
        });
      }
    },
    selectAll(type) {
      if (type === "variable") {
        this.selection.variables = ["all"];
        this.getData();
      }

      if (type === "location") {
        this.selection.locations = ["all"];
        this.getData();
      }
    },
    resetAll() {
      this.selection = {
        variables: "",
        locations: this.options.locations[0].Localname,
        date: "",
      };

      this.getData();
    },
    async getData(i) {
      // await this.removeCommaFromLocalname();
      /* check max 5 location to be selected */
      if (
        this.selection.locations.length &&
        this.selection.locations.length > 5
      ) {
        let input = document.getElementById(`water-quality-location-${i}`);
        input.checked = false;
        this.selection.locations.pop();
        this.$toast.error("Only max 5 locations allowed at a time");
      }

      let params = `?page_size=999999`;
      /*  if (this.selection.variables.length > 0) {
        if (this.selection.variables.length > 1) {
          const index = this.selection.variables.indexOf("all");
          if (index > -1) {
            this.selection.variables.splice(index, 1);
          }
        }
        if (!this.selection.variables.includes("all")) {
          let variables = "";
          this.selection.variables.forEach((el) => {
            variables += `${el},`;
          });
          params += `&variable__in=${variables}`;
          this.loc = false;
        }
      } */
      if (this.selection.variables != "") {
        params += `&variable__in=${this.selection.variables}`;
      }
      if (this.selection.locations.length > 0) {
        if (this.selection.locations.length > 1) {
          const index = this.selection.locations.indexOf("all");
          if (index > -1) {
            this.selection.locations.splice(index, 1);
          }
        }
        if (!this.selection.locations.includes("all")) {
          let locations = "";
          this.selection.locations.forEach((el) => {
            locations += `${el},`;
          });
          params += `&location__Localname__in=${locations}`;
          this.loc = true;
        }
      }

      if (this.selection.date !== "") {
        params += `&date=${this.selection.date}`;
        this.loc = false;
      }

      await this.$repository.timeSeries.filter(params).then(async (res) => {
        this.timeSeries = await res.data.results;

        /*  if (!this.selection.locations.includes("all")) {
          if (this.loc) {
            if (this.selection.locations.length !== 0) {
              this.options.variables = [];
              this.timeSeries.forEach((el) => {
                this.options.variables.push(el.variable);
              });
            } else {
              await this.$repository.variables.all().then((res) => {
                this.options.variables = res.data.results;
              });
            }
          }
        } else {
          await this.$repository.variables.all().then((res) => {
            this.options.variables = res.data.results;
          });
        } */

        if (!this.var_d) {
          this.options.variables = [];
          await this.timeSeries.forEach((el) => {
            this.options.variables.push(el.variable);
          });
          if (this.options.variables.length) {
            this.selection.variables = this.options.variables[0].variable;
          }
        }

        // removing duplicates
        this.options.variables = this.options.variables.filter(
          (v, i, a) => a.findIndex((t) => t.variable_id === v.variable_id) === i
        );

        await this.timeSeries.forEach((el) => {
          el.var = el.location.Localname;
        });

        let result = this.timeSeries.reduce(function (r, a) {
          r[a.var] = r[a.var] || [];
          r[a.var].push(a);
          return r;
        }, Object.create(null));

        let dataArray = [];
        for (let i in result) {
          let arr = [];
          result[i].forEach((el) => {
            arr.push({
              x: new Date(el.date).getTime(),
              y: el.value,
              unit: el.variable.Unit,
            });
          });
          dataArray.push({
            name: i.replaceAll("_", " ").toUpperCase(),
            data: arr,
          });
        }
        if (dataArray.length) {
          dataArray.forEach((el) => {
            el.data.sort(this.dynamicSort("x"));
          });
        }
        let tempLoc = await dataArray.map((el) => {
          return el.name;
        });

        this.selection.locations.forEach((el) => {
          if (!tempLoc.includes(el.toUpperCase())) {
            //no data
            dataArray.push({
              data: [],
              name: `${el.toUpperCase()} (NO DATA)`,
            });
          }
        });

        this.chartOptions = {
          yAxis: {
            labels: {
              formatter: function () {
                return (this.value > 0 ? " + " : "") + this.value;
              },
            },
            plotLines: [
              {
                value: 0,
                width: 2,
                color: "silver",
              },
            ],
            title: {
              offset: 15,
              text: this.selection.variables,
              rotation: 90,
              y: -10,
            },
          },

          tooltip: {
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> {point.unit}<br/>',
            valueDecimals: 4,
            split: true,
          },

          series: dataArray,
          credits: {
            enabled: false,
          },
          colors: this.colors,
          legend: {
            enabled: true,
          },
        };

        this.loading = false;
        this.var_d = false;
      });
    },
    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    },
    resetFilter() {
      this.selection.variables = "";
      this.selection.locations = [];
      this.selection.locations.push(this.options.locations[0].Localname);
      this.selection.date = "";

      this.getData();
    },
  },
};
</script>

<style scoped>
.spinner-content {
  position: relative;
  height: 400px;
}
.spinner {
  width: 70px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: var(--theme-color);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
input {
  color: #6c757d;
  border-color: #6c757d;
}

.form-check-label {
  font-weight: normal;
  font-size: 14px;
}
caption {
  caption-side: top !important;
  color: black !important;
}
.selection th {
  color: black;
  opacity: 0.7;
}
</style>
<style scoped>
#selectionValuesModal .modal-dialog {
  max-width: 80%;
  overflow: initial !important;
}
#selectionValuesModal .modal-body {
  height: 80vh;
  overflow-y: auto;
}
</style>
