import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import NotFound from "../views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: false,
      title: "Home | KVWSMB",
    },
  },
  // {
  //     path: '/test',
  //     name: 'test',
  //     component: () => import( '../views/Test'),
  //     meta: {
  //         requiresAuth: false,
  //         title: 'Test | KVWSMB'
  //     }
  // },
  // {
  //     path: '/tiff',
  //     name: 'tiff',
  //     component: () => import( '../views/TiffTest'),
  //     meta: {
  //         requiresAuth: false,
  //         title: 'Tiff | KVWSMB'
  //     }
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login"),
    meta: {
      requiresAuth: false,
      title: "Login | KVWSMB",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register"),
    meta: {
      requiresAuth: false,
      title: "Register | KVWSMB",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/Profile"),
    meta: {
      requiresAuth: true,
      title: "Profile | KVWSMB",
    },
  },
  {
    path: "/password-reset/request",
    name: "password-reset-request",
    component: () => import("../views/password_reset/PasswordResetRequest"),
    meta: {
      requiresAuth: false,
      title: "Password Reset | KVWSMB",
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("../views/password_reset/PasswordReset"),
    meta: {
      requiresAuth: false,
      title: "Password Reset | KVWSMB",
    },
  },
  {
    path: "/ChangePassword",
    name: "change-password",
    component: () => import("../views/password_reset/ChangePassword"),
    meta: {
      requiresAuth: false,
      title: "Password Change | KVWSMB",
    },
  },
  {
    path: "/dataset",
    name: "dataset",
    component: () => import("../views/datasets/Dataset"),
    meta: {
      requiresAuth: false,
      title: "Dataset | KVWSMB",
    },
  },
  {
    path: "/dataset/:id",
    name: "dataset-view",
    component: () => import("../views/datasets/ViewDataset"),
    meta: {
      requiresAuth: false,
      title: "Layer | KVWSMB",
    },
  },
  {
    path: "/time-series",
    name: "time-series",
    component: () =>
      import(
        /* webpackChunkName: "time-series" */ "../views/timeseries/TimeSeries"
      ),
    meta: {
      requiresAuth: false,
      title: "Time Series | KVWSMB",
    },
  },
  {
    path: "/variable/add",
    name: "variable-add",
    component: () =>
      import(
        /* webpackChunkName: "time-series" */ "../views/variables/AddVariables"
      ),
    meta: {
      requiresAuth: false,
      title: "Variable | KVWSMB",
    },
  },
  {
    path: "/file-types/add",
    name: "file-types-add",
    component: () =>
      import(
        /* webpackChunkName: "time-series" */ "../views/file_types/AddFileType"
      ),
    meta: {
      requiresAuth: false,
      title: "File Types | KVWSMB",
    },
  },
  {
    path: "/location-data",
    name: "location-data",
    component: () =>
      import(
        /* webpackChunkName: "location-data" */ "../views/location/LocationData"
      ),
    meta: {
      requiresAuth: false,
      title: "Location | KVWSMB",
    },
  },
  {
    path: "/study-reports",
    name: "study-reports",
    component: () =>
      import(
        /* webpackChunkName: "study-reports" */ "../views/study_reports/StudyReports"
      ),
    meta: {
      requiresAuth: false,
      title: "Study Reports | KVWSMB",
    },
  },
  {
    path: "/study-reports/:id",
    name: "view-study-report",
    component: () =>
      import(
        /* webpackChunkName: "study-reports" */ "../views/study_reports/ViewStudyReport"
      ),
    meta: {
      requiresAuth: false,
      title: "Study Reports | KVWSMB",
    },
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (
    (to.name === "register" || to.name === "login") &&
    store.state.auth.isLoggedIn
  ) {
    next({ name: "home" });
  } else if (to.meta.requiresAuth) {
    if (!store.state.auth.isLoggedIn) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
