var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-body"},[_c('div',{staticClass:"addacc_form row"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 mb-3"},[_c('validation-provider',{attrs:{"name":"layer name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"layer-name"}},[_vm._v("Layer Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Layer_Name),expression:"form.Layer_Name"}],staticClass:"form-control",class:{
                  'is-invalid': errors[0],
                  'is-valid': !errors[0] && !pristine,
                },attrs:{"type":"text","id":"layer-name","placeholder":"Layer Name"},domProps:{"value":(_vm.form.Layer_Name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Layer_Name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3"},[_c('validation-provider',{attrs:{"name":"document","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var pristine = ref.pristine;
                var validate = ref.validate;
return [_c('label',{staticClass:"form-label",attrs:{"for":"document"}},[_vm._v("Document")]),_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('input',{ref:"file",staticClass:"form-control me-3",class:{
                    'is-invalid': errors[0],
                    'is-valid': !errors[0] && !pristine,
                  },attrs:{"type":"file","id":"document"},on:{"input":validate,"change":_vm.processFile}}),_c('label',[_vm._v(_vm._s(_vm.documentName))])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3"},[_c('validation-provider',{attrs:{"name":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"description"}},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",class:{
                  'is-invalid': errors[0],
                  'is-valid': !errors[0] && !pristine,
                },attrs:{"type":"text","id":"description"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3"},[_c('validation-provider',{attrs:{"name":"keywords","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"keywords"}},[_vm._v("Keywords")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.keywords),expression:"form.keywords"}],staticClass:"form-control",class:{
                  'is-invalid': errors[0],
                  'is-valid': !errors[0] && !pristine,
                },attrs:{"type":"text","id":"keywords","placeholder":"Keywords"},domProps:{"value":(_vm.form.keywords)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "keywords", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3"},[_c('validation-provider',{attrs:{"name":"File Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label"},[_vm._v("File Type")]),_c('v-select',{staticClass:"bg-white",class:{
                  'is-invalid': errors[0],
                  'is-valid': !errors[0] && !pristine,
                },attrs:{"placeholder":"Select File Type","options":_vm.options.fileTypes,"label":"Filetype","reduce":function (item) { return item.Filetype; }},model:{value:(_vm.form.File_Type),callback:function ($$v) {_vm.$set(_vm.form, "File_Type", $$v)},expression:"form.File_Type"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3"},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label"},[_vm._v("Category")]),_c('v-select',{staticClass:"bg-white",class:{
                  'is-invalid': errors[0],
                  'is-valid': !errors[0] && !pristine,
                },attrs:{"placeholder":"Select Category","options":_vm.options.categories,"label":"Category","reduce":function (item) { return item.Category; }},model:{value:(_vm.form.Category),callback:function ($$v) {_vm.$set(_vm.form, "Category", $$v)},expression:"form.Category"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[(!_vm.saving)?_c('button',{staticClass:"btn btn-primary px-2 btn-sm me-3",attrs:{"type":"submit"}},[_vm._v(" Update ")]):_c('button',{staticClass:"btn btn-primary px-2 btn-sm me-3",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")]),_vm._t("cancel")],2)])])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }